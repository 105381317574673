const url_parkingdata = process.env.REACT_APP_MAPPING_DATA_LINK
const url_cocodata_service = process.env.REACT_APP_COCODATA_LINK
const url_cocopilot = process.env.REACT_APP_COCOPILOT_LINK

export const getCustomerConfig = url_parkingdata + "/config/"

//////////////          FETCH PARKING DATA          //////////////////////
export const closestParking = url_parkingdata + "/poi/parkings/closest"
export const locationLiveInfo = url_parkingdata + "/city/location_live_info"
export const parkingData = url_parkingdata + "/poi/parkings"
export const locationSpotsData = url_parkingdata + "/city/location_spots"
export const parkingSpotAvailabilities = url_parkingdata + "/poi/parkings/spots_availability"
export const revealSlots = url_parkingdata + "/features/torch"
export const optimalItinerary = url_parkingdata + "/features/optimal_path"
export const classicRouting = url_parkingdata + "/features/geo/directions"
export const liveviewData = url_parkingdata + "/poi/liveviewzones"
export const liveviewStaticData = url_parkingdata + "/poi/liveviewzones/static"
export const liveviewDynamicData = url_parkingdata + "/poi/liveviewzones/dynamic"
export const closestAvailableLvz = url_parkingdata + "/poi/liveviewzones/closest"
export const closesLvzNearby = url_parkingdata + "/poi/liveviewzones/nearby"


export const checkUserExistInDB = url_cocodata_service + "/checkuser"
export const getUser = url_cocodata_service + "/user/"
export const postCarType = url_cocodata_service + "/cartype"
export const postFuelType = url_cocodata_service + "/fueltype"
export const postAddress = url_cocodata_service + "/useraddresses"
export const readHistory = url_cocodata_service + "/readhist"
export const updateUser = url_cocodata_service + "/updateuser"
export const createUser = url_cocodata_service + "/createuser"
export const updateIp = url_cocodata_service + "/updateip"
export const newHist = url_cocodata_service + "/newhist"
export const updateHist = url_cocodata_service + "/updatehist"
export const createUserSession = url_cocodata_service + "/createUserSession"
export const addActionToUserSession = url_cocodata_service + "/addActionToUserSession"
export const storeFeedback = url_cocodata_service + "/storefeedback"
export const newAppInstallUser = url_cocodata_service + "/newAppInstallUser"
export const newAppInstall = url_cocodata_service + "/newAppInstall"
export const updateUserField = url_cocodata_service + "/update/"
export const getAnonymousUser = url_cocodata_service + "/anonymous/"

export const liveviewZoneData = url_cocopilot + "/clients"
export const liveviewZoneRealTimeFreeSlotsParkings = url_cocopilot + "/entities/parking/slots/static"
export const liveviewZoneRealTimeFreeSlotsZones = url_cocopilot + "/entities/zone/slots/static"

export const apiAirtableBase = "https://api.airtable.com/v0/" + process.env.REACT_APP_API_AIRTABLE_BASE_URL