import { handleResponse, transfromObjectToQueryString } from "./utilsApi"
import * as url from './routes'

/**
  * to get all parkings data around a certain position
  * @param {object} position - object containing lat and lng properties
  */
export async function getParkingData(position){
    let dataToSend = {
        location: {
          lat: position.lat,
          lng: position.lng
        },
        loaded_parkings: []
    };
    
    var response = fetch(url.parkingData, {
            method: 'post',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(error => console.log(error))
    return response
};


/**
  * to get all parkings data around a certain position
  * @param {object} position - object containing lat and lng properties
  * @param {array} loadedCityzones -  actual google maps markers by slottype
  */
export async function getLocationSpots(position, loadedCityzones){

    let dataToSend

    if(loadedCityzones.length === 0){
        dataToSend = transfromObjectToQueryString({
            lat: position.lat,
            lng: position.lng,
        });
    } else {
        dataToSend = transfromObjectToQueryString({
            lat: position.lat,
            lng: position.lng,
            loaded_cityzones: loadedCityzones,
        });
    }

    let response = fetch(url.locationSpotsData + dataToSend)
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log(error))
    return response
};


/**
  * to get the spots availabilities of parking
  */
 export async function getParkingsSpotsAvailabilities(data){
    let dataToSend = data;

    let response = fetch(url.parkingSpotAvailabilities, {
        method: 'post',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log(error))
    return response
};


/**
 * Getting postcode from position
 * @param {object} position - {lat: number, lng: number} position to get postcode from
 */
export function getPostCode(position){
    let response = fetch(`https://nominatim.openstreetmap.org/reverse?lat=${position.lat}&lon=${position.lng}`)
    .then(response=>response.text())
    .then(data=>{
        let parser = new DOMParser();
        let xml = parser.parseFromString(data, "application/xml");
        let result = xml.getElementsByTagName('postcode');
        let postcode = result[0].innerHTML
        let dpt = postcode.substring(0, 2)
        return dpt
    })
    .catch(error => console.log(error))
    return response
}


/**
 * Getting postcode from position
 * @param {object} position - {lat: number, lng: number} position to get postcode from
 */
export function getCity(position){
    let response = fetch(`https://nominatim.openstreetmap.org/reverse?lat=${position.lat}&lon=${position.lng}`)
    .then(response=>response.text())
    .then(data=>{
        let parser = new DOMParser();
        let xml = parser.parseFromString(data, "application/xml");
        let resultCity = xml.getElementsByTagName('city');
        if(resultCity.length > 0){
            let city = resultCity[0].innerHTML
            return city
        } else {
            return null
        }
    })
    .catch(error => console.log(error))
    return response
}

// Fetches custom markers from Airtable based on the provided source identifier.
export const fetchCustomMarkersFromAirtable = async (source) => {
    if (!source || typeof source !== 'string') {
        return [];
    }
    try {
        const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
        const filterByFormula = `specific_url="source=${source}"`; // Source is the identifier for the data source (e.g., 'ampqrcode')

        const response = await fetch(`${url.apiAirtableBase}/CustomMarkers?filterByFormula=${encodeURIComponent(filterByFormula)}`, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch custom markers");
        }

        const data = await response.json();
        if(data.records.length === 0) {
            return [];
        }
        return data.records.map(record => {
            const [latStr, lngStr] = record.fields.coordinates.split(',');
            const lat = parseFloat(latStr);
            const lng = parseFloat(lngStr);

            // Validate lat and lng
            if (isNaN(lat) || isNaN(lng)) {
                console.warn(`Invalid lat/lng for record ${record.id}:`, record.fields);
                return null;
            }

            return {
                id: record.id,
                lat,
                lng,
                icon: record.fields.icon_url,
                description: record.fields.description,
            };
        }).filter(Boolean); // Remove null values (invalid records)


    } catch (error) {
        console.error("Error fetching custom markers:", error);
        return [];
    }
};