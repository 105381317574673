/**
 * Checks if the current time falls within the specified parking open hours.
 * @param {Object} openHours - Object containing parking open hours for each weekday.
 * @param {string} timezone - Timezone in the format "Europe/Paris".
 * @returns {boolean} - True if the parking is open, false otherwise.
 */
export function isCurrentTimeInParkingOpenHours(openHours, timezone) {
  if (openHours == null) {
    return true;
  }

  /**
   * PATCH: In the database some open_hours are
   * incorrect strings (there are many).
   * Which bugs the app with a blank page.
   * To debug the app, we consider that the parking is open
   */
  if (typeof openHours === "string") {
    return true;
  }

  if (Object.keys(openHours).length === 0) {
    return true; // Parking is open if openHours is empty
  }

  if (timezone === null || timezone === undefined) {
    timezone = "Europe/Paris";
  }

  // Function to convert string time to Date object in specified timezone
  function convertToTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const nowInTimezone = new Date().toLocaleString("en-US", {
      timeZone: timezone,
    });
    const timeInTimezone = new Date(nowInTimezone);
    timeInTimezone.setHours(hours, minutes);
    return timeInTimezone;
  }

  // Get the current Date and time in specified timezone
  const now = new Date().toLocaleString("en-US", { timeZone: timezone });

  // Get the current weekday (0 = Monday, ..., 6 = Sunday)
  const currentWeekday = (new Date(now).getDay() + 6) % 7;

  // Get the current time
  const currentTime = new Date(now);

  // Get opening hours of current weekday
  const currentWeekdayOpenHours = openHours[currentWeekday.toString()];

  // IF there are not openng hours for
  // current weekday, we consder that
  // the parking is close
  if (currentWeekdayOpenHours === undefined) {
    return false;
  }

  // Check if the current time is within currentWeekdayOpenHours
  for (const opening of currentWeekdayOpenHours) {
    const openingFrom =
      opening.from !== null && opening.from !== undefined
        ? convertToTime(opening.from)
        : convertToTime("00:00");
    const openingTo =
      opening.to !== null && opening.to !== undefined
        ? convertToTime(opening.to)
        : convertToTime("23:59");
    if (openingFrom <= currentTime && currentTime <= openingTo) {
      return true;
    }
  }

  return false;
}

// This function, debounce, implements a debouncing mechanism for the provided function func. 
// It delays the execution of func until a certain amount of time (wait) has passed since the last invocation.
export function debounce(func, wait) {
  let timeout;
  return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
  };
}