import { isCurrentTimeInParkingOpenHours } from "../utils";

////////////////////////////// ALL FUNCTIONS USED IN MAP ////////////////////////////////////

//To calculate time to the closest parking from position
export function calculateDistanceToClosetParking(position, parking) {
  // calculate with Harverstine formula
  let closestParkingLatLng = { lat: parking.lat, lng: parking.lng };
  let distToClosestParking = getDistance(position, closestParkingLatLng);

  // driving distance vs vol d'oiseau
  let drivingDistFactor = 3;
  let drivingDistToClosestParking = distToClosestParking * drivingDistFactor;
  let averageSpeed = 15; //in km/h
  let averageSpeedInMeterByMin = (averageSpeed * 1000) / 60;
  //console.log(averageSpeed);

  // calculate time to closest parking & adds the right time format
  let timeToClosestParking = timeFormat(
    (drivingDistToClosestParking / averageSpeedInMeterByMin) * 60,
  );
  return timeToClosestParking;
}

//To calculate the position of the label "15min" based on the circle's radius
export function calculateLabelPosition(position, circle) {
  let radius = circle.radius;
  let spherical = window.google.maps.geometry.spherical;
  let resultPosition = spherical.computeOffset(
    new window.google.maps.LatLng(position.lat, position.lng),
    radius,
    0,
  );
  return resultPosition;
}

//To calculate the distance between the center of the map and the user location. If it's more than 300m, switch to researchMode by setting aroundMeModeActive to false
export function isAroundMeModeActive(maps, userLocation) {
  return new Promise((resolve) => {
    let actualMapCenter = maps.getCenter().toJSON();
    let distance = getDistance(actualMapCenter, userLocation);
    if (distance > 300) {
      resolve(false);
    } else {
      resolve(true);
    }
  });
}

// harverstine formula
export function getDistance(p1, p2) {
  let R = 6378137; // Earth’s mean radius in meter
  let dLat = rad(p2.lat - p1.lat);
  let dLong = rad(p2.lng - p1.lng);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d; // returns the distance in meter
}

//to center map on a given lat & lng
export function centerMapOnMarker(lat, lng, map) {
  let latLng = new window.google.maps.LatLng(lat, lng);
  map.panTo(latLng);
}

//to find the zoom status of a type of marker. Based on the zoom status we display a specific icon
export function findMarkerStatus(zoomThresholdsArray, iconsArray, zoom) {
  if (
    iconsArray.length === zoomThresholdsArray.length - 1 ||
    iconsArray.length === 0
  ) {
    if (zoomThresholdsArray.length === 4) {
      // small zoom // dezoomed map
      if (zoom < zoomThresholdsArray[1]) {
        return 0;
      }
      // highest zoom
      else if (zoom >= zoomThresholdsArray[2]) {
        return 2;
      } else return 1;
    } else if (zoomThresholdsArray.length > 4) {
      let index;
      for (let i = 0; i < zoomThresholdsArray.length; i++) {
        if (zoom < zoomThresholdsArray[i]) {
          index = i - 1;
          break;
        }
      }
      return index;
    } else {
      // arraylength = 3 which means only 1 threshold
      if (zoom <= zoomThresholdsArray[1]) {
        return 0;
      }
      // high zoom
      else return 1;
    }
  } else {
    console.log("error in icons structure");
  }
}

/**
 * returns [points, distance, duration, boundsOfOD() for map screen sizing]
 * @param {Object} origin - origin {lat: nume, lng: number}
 * @param {Object} destination - Destination {lat: nume, lng: number}
 * @param {String} mode - mode to go to the destination (WALKING, DRIVING)
 */
export function directionsOD(origin, destination, mode) {
  return new Promise((resolve) => {
    let points = [];
    let bounds = new window.google.maps.LatLngBounds();
    bounds.extend(origin);
    bounds.extend(destination);

    let request = {
      origin: origin,
      destination: destination,
      travelMode: mode,
      drivingOptions: {
        departureTime: new Date(),
        trafficModel: "bestguess", // pessimistic or optimistic or bestguess
      },
    };

    let response;
    let directionsService = new window.google.maps.DirectionsService();

    directionsService.route(request, function (result, status) {
      if (status !== "OVER_QUERY_LIMIT" && result.routes.length > 0) {
        let myRoute = result.routes[0].legs[0];
        if (status === window.google.maps.DirectionsStatus.OK) {
          for (let i = 0; i < myRoute.steps.length; i++) {
            for (let j = 0; j < myRoute.steps[i].lat_lngs.length; j++) {
              points.push(myRoute.steps[i].lat_lngs[j]);
            }
          }
        }

        let distanceOD = myRoute.distance.value;
        let TT_OD = myRoute.duration.value;

        response = [points, distanceOD, TT_OD, bounds];
      } else {
        response = "error";
      }
      resolve(response);
    });
  });
}

/**
 * To get the driving time from a location to a destination
 * @param {Object} origin - Origin {lat: number, lng: number}
 * @param {Object} destination - Destination {lat: number, lng: number}
 */
export async function getDriveTimeAndPath(origin, destination) {
  let driveTime = "-";
  let drivePath = [];
  let resp = await directionsOD(origin, destination, "DRIVING");
  if (resp !== "error") {
    driveTime = timeFormat(resp[2]);
    drivePath = resp[0];
  }
  return { driveTime: driveTime, drivePath: drivePath };
}

/**
 * To get the walking time from a location to a destination
 * @param {Object} origin - Origin {lat: number, lng: number}
 * @param {Object} destination - Destination {lat: number, lng: number}
 */
export async function getWalkingTime(origin, destination) {
  let walkTime = "-";
  let walkPath = await directionsOD(origin, destination, "WALKING");
  if (walkPath !== "error") {
    walkTime = timeFormat(walkPath[2]);
  }
  return walkTime;
}

/**
 * To get the walking and driving time + walking and driving distance from a location to a destination
 * @param {Object} origin - Origin {lat: number, lng: number}
 * @param {Object} destination - Destination {lat: number, lng: number}
 */
export async function getTimeAndDistance(origin, destination) {
  let drivePath = await directionsOD(origin, destination, "DRIVING");
  let walkPath = await directionsOD(origin, destination, "WALKING");

  var driveTime = timeFormat(drivePath[2]);
  var walkTime = timeFormat(walkPath[2]);
  var driveDist = distFormat(drivePath[1]);
  var walkDist = distFormat(walkPath[1]);

  var res = [driveTime, driveDist, walkTime, walkDist];
  return res;
}

/**
 * To get the time and the distance from a location to a destination based on driving or walking
 * @param {Object} origin - Origin {lat: number, lng: number}
 * @param {Object} destination - Destination {lat: number, lng: number}
 * @param {String} type - Either DRIVING or WALKING
 */
export async function getTimeAndDistanceBasedOnType(origin, destination, type) {
  let res;
  let typePath = await directionsOD(origin, destination, type);

  if (typePath !== "error") {
    let time = timeFormat(typePath[2]);
    let dist = distFormat(typePath[1]);
    res = [time, dist];
  } else {
    res = [null, null];
  }

  return res;
}

/**
 * To call to the google geocoder api and returns string with full address
 * @param {Object} position - position {lat: nume, lng: number}
 */
export function geocodeLatLng(position) {
  return new Promise((resolve) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          let response = {
            fulladdress: results[0].formatted_address,
          };
          let addressComp = results[0]["address_components"];
          addressComp.forEach((item) => {
            if (item["types"][0] === "postal_code") {
              response.postcode = item["long_name"];
            }
          });
          resolve(response);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  });
}

// takes center of research as a marker, and markers array as params
export function getInsightsFromResearch(center, markersArray) {
  return new Promise((resolve) => {
    let smallestDist;
    let dist;
    let distsArray = [];
    let x = 0;
    let indexOfMarker = 0;
    let nbWithin300 = 0;
    let parkingsWithin300 = [];
    let indexOfParkingsWithin300 = [];
    let nbWithin600 = 0;
    let parkingsWithin600 = [];
    let indexOfParkingsWithin600 = [];
    let closestMarker;
    let radiusFive = 300;
    let radiusTen = 600;

    markersArray.forEach((item) => {
      if (item.location !== null) {
        dist = getDistance(center, item.location);
        distsArray.push(dist);
        if (dist <= radiusTen) {
          nbWithin600 = nbWithin600 + 1;
          parkingsWithin600.push(item);
          indexOfParkingsWithin600.push(x);
        }
        if (dist <= radiusFive) {
          nbWithin300 = nbWithin300 + 1;
          parkingsWithin300.push(item);
          indexOfParkingsWithin300.push(x);
        }
      }
      if (smallestDist == null) {
        smallestDist = dist;
        x = x + 1;
      } else {
        if (dist < smallestDist) {
          indexOfMarker = x;
          smallestDist = dist;
        }
        x = x + 1;
      }
      if (x === markersArray.length) {
        let ranksDists = ranksOfArray(distsArray);
        closestMarker = markersArray[indexOfMarker];
        let researchInsights = {
          //closest marker info
          closestMarker: closestMarker,
          indexOfClosestMarker: indexOfMarker,
          smallestDist: smallestDist,
          //parkings within 10 minutes
          nbWithin600: nbWithin600,
          parkingsWithin600: parkingsWithin600,
          indexArrayOfWithin600: indexOfParkingsWithin600,
          nbWithin300: nbWithin300,
          parkingsWithin300: parkingsWithin300,
          indexArrayOfWithin300: indexOfParkingsWithin300,
          //overall parkings:
          distsArray: distsArray,
          ranksDists: ranksDists,
        };
        resolve(researchInsights);
      }
    });
  });
}

// three options: google, apple, waze; and then close appSelectPanel
export function goToParking(deviceSystem, destination, parkingEntry) {
  let lat = destination.lat;
  let lng = destination.lng;

  let strGoogle = String(lat) + "," + String(lng);
  let strWaze = String(lat) + "%2C" + String(lng);
  let strApple = String(lat) + ">,<" + String(lng);

  if (deviceSystem === "apple") {
    window.open("maps://maps.google.com/maps?daddr=<" + strApple + ">&amp;ll=");
  } else if (deviceSystem === "google") {
    const destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${strGoogle}&travelmode=driving`;
    const waypointUrl = parkingEntry 
      ? `${destinationUrl}&waypoints=${parkingEntry.lat},${parkingEntry.lng}`
      : destinationUrl;
    window.open(waypointUrl);
  } else if (deviceSystem === "waze") {
    window.open(
      "https://www.waze.com/ul?ll=" + strWaze + "&navigate=yes&zoom=17",
    );
  }
}



//to return an object based on index from parkingMarkerData
export function createObjectWithDataFromIndex(array, index) {
  const openHours =
    typeof array[index].open_hours === "object" ? array[index].open_hours : {};
  let obj = {
    data: {
      operator: array[index].operator,
      address: array[index].address,
      name: array[index].name,
      reservation: array[index].reservation_link,
      reservationOnly: array[index].reservation_only,
      openHours: openHours,
      isOpenNow: isCurrentTimeInParkingOpenHours(
        openHours,
        array[index].timezone,
      ),
      price: {
        price1: array[index].price_1_h,
        price3: array[index].price_3_h,
        price24: array[index].price_24_h,
      },
      parkingInfo: [
        array[index].is_24_7,
        array[index].reservation_link,
        array[index].e_charge,
        array[index].restroom,
        array[index].carwash,
        array[index].access_disabled,
      ],
      index: array[index].index,
      location: {
        lat: array[index].location.lat,
        lng: array[index].location.lng,
      },
      specific_message: array[index].specific_message,
    },
    key: index,
  };
  return obj;
}

////////////////////////////// UTILS FUNCTIONS FOR MAP  ////////////////////////////////////

//transform to radian
function rad(x) {
  return (x * Math.PI) / 180;
}

// nb is in seconds; returns string in correct format
export function timeFormat(nb) {
  let x = Math.round(nb / 60);
  if (x < 1) {
    return "<1";
  } else if (x > 60) {
    return ">60";
  } else {
    return String(x);
  }
}

//to generate a custom key based on 2 arguments
export function generateKey(arg1, arg2) {
  return `${arg1}_${arg2}`;
}

/**
 * From array with values, returns an Array with the ranks
 * @param {Array} arr
 */
export function ranksOfArray(arr) {
  // console.log("arr", arr);
  let sorted = arr.slice().sort(function (a, b) {
    return a - b;
  });
  // console.log("sorted", sorted);
  let ranks = arr.map(function (v) {
    return sorted.indexOf(v) + 1;
  });
  // console.log("ranks", ranks);
  return ranks;
}

/**
 * converts distance to time based on distance conversion factor (vol d'oiseau vs streets)
 * and based on avgs speed
 *
 * @param {float} distance - in km
 * @returns {string} - time to go
 */
export function convertDistanceToTime(distance) {
  return new Promise((resolve) => {
    // driving distance vs vol d'oiseau
    let drivingDistFactor = 3;
    let drivingDistToClosestParking = distance * drivingDistFactor;
    let averageSpeed = 15; //in km/h
    let averageSpeedInMeterByMin = (averageSpeed * 1000) / 60;
    //console.log(averageSpeed);
    // calculate time to closest parking & adds the right time format
    let timeToClosestParking = timeFormat(
      (drivingDistToClosestParking / averageSpeedInMeterByMin) * 60,
    );
    resolve(timeToClosestParking);
  });
}

/**
 * to return a string to use
 * @param {number} nb - in km
 */
export function distFormat(nb) {
  // if < 1km show in meter
  let result;
  if (nb < 1000) {
    result = String(nb) + "m";
    return result;
  } else {
    result = String(Math.round(nb / 1000, 1)) + "km";
    return result;
  }
}
