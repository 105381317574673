import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTORS
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleGoingToCloseParking } from '../itinerary/state/displayGoingToCloseParkingAction'
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';
import { toggleConfirmationLeavingItinerary } from '../itinerary/state/displayConfirmationLeavingItineraryAction';
import { updateNbOfSlotsPassed } from './state/nbOfSlotsPassedAction';
import { resetPathToDestination } from '../markers/state/directionToDestinationAction';
import { resetItineraryPath } from './state/itineraryPathAction';
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { toggleResearchMode } from '../global/state/researchModeAction'
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as IoIcon from 'react-icons/io5';

function GoingToCloseParking(props) {

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const dispatch = useDispatch()

    function hidingGoingBackToCloseParking(){
        dispatch(toggleGoingToCloseParking(false))
    }

    function showItineraryToClosestParking(){
        confirmLeavingItinerary()
        hidingGoingBackToCloseParking()
        let center = props.map.getCenter().toJSON() 
        showExpandedDetailsPanel(center)
    }

    //to leave itinerary mode
    function confirmLeavingItinerary(){
        dispatch(toggleItineraryMode(false))
        dispatch(showDetailPanelContainer())
        dispatch(toggleConfirmationLeavingItinerary(false))
        dispatch(updateNbOfSlotsPassed(0))
        dispatch(resetPathToDestination())
        dispatch(resetItineraryPath())
    }

    //to open expandedDetailsPanel and display the closest parking
    function showExpandedDetailsPanel(location){
        dispatch(toggleExpandedDetailPanel(true))
        dispatch(toggleResearchMode({value: true, location: location}))
        dispatch(showCocoSearchMarker(location))
    }

    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px", color: "white"}}
                onClick={()=> {hidingGoingBackToCloseParking(); addActionToDbBasedOnElement("confirmWantToGoToParking_close", trackingIdAndAnonymousLocal, trackingSessionId)}}
            />
            <div className="time-park-button-div">
                <button className="green-button-time-to-park"><p className="no-margin" onClick={()=> {showItineraryToClosestParking(); addActionToDbBasedOnElement("confirmWantToGoToParking_findParking", trackingIdAndAnonymousLocal, trackingSessionId)}}>TROUVER UN PARKING</p></button>
            </div>
            <div className="time-park-button-div">
                <button className="green-button-time-to-park" style={{backgroundColor: "var(--dark-blue)"}}><p className="no-margin" onClick={()=> {hidingGoingBackToCloseParking(); addActionToDbBasedOnElement("confirmWantToGoToParking_backToMap", trackingIdAndAnonymousLocal, trackingSessionId)}}>REVENIR A L'ITINERAIRE</p></button>
            </div>
        </div>
    )
    
}

export default GoingToCloseParking
