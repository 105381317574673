export const UPDATE_SPECIFIC_LVZ_DATA = 'UPDATE_SPECIFIC_LVZ_DATA'
export const RESET_SPECIFIC_LVZ_DATA = 'RESET_SPECIFIC_LVZ_DATA'

export function specificLvzDataReducer (state = null, action) {
    switch (action.type){
        case UPDATE_SPECIFIC_LVZ_DATA:
            return {...state, ...action.payload}
        case RESET_SPECIFIC_LVZ_DATA:
            return null
        default: return state
    }
}