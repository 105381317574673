import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { deferredPromptSelector } from '../installation/state/deferredPromptSelector'

//REDUX ACTIONS
import { toggleInstallCocoparks } from './state/displayInstallCocoparksAction'
import { toggleInstallationLegendIphone } from './state/displayInstallationLegendIphoneAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'
import { addDataToUserSessionInDB, addNewAppInstallInDbUser, addNewAppInstallInDB } from '../api/userTrackingApi.js'

//STYLE
import './InstallCocoparks.css'
import logo from '../images/logo.png'
import * as IoIcon from 'react-icons/io5';

function InstallCocoparks() {

    const dispatch = useDispatch()

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const deferredPrompt = useSelector(deferredPromptSelector)

    //to hide the actual component
    function closeInstallCocoparks(){
        dispatch(toggleInstallCocoparks(false))
    }

    function installRequest(){
        //If mobile is not iphone we request the installation of app
        if(navigator.platform !== "iPhone"){
            if(deferredPrompt){
                deferredPrompt.prompt()
                deferredPrompt.userChoice.then(result =>
                    {
                        if(result.outcome === 'accepted')
                        {
                            // console.log('User accepted the install prompt')
                            // Track if the user downloaded the app or not
                            let dataTracking = {
                                appinstalled:true,
                            };
                            addNewAppInstallInDbUser(trackingIdAndAnonymousLocal[0], trackingIdAndAnonymousLocal[1]);
                            addNewAppInstallInDB(trackingIdAndAnonymousLocal[0]);
                            addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)
                            localStorage.setItem('installGood', '1')
                        }
                        else
                        {
                        //   console.log('User dismissed the install prompt')
                            // Track if the user downloaded the app or not
                            let dataTracking = {
                                appinstalled: false,
                            };
                            addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)
                        }
                    })
            }
        } else {
            //for iOS, juste open div and when user click on install, an other div open for guide the user
            // And the app isn't installed
            if(navigator.standalone !== true ){
                localStorage.setItem('installGood', '1')
                let dataTracking = {
                    appinstalled:true,
                };
                addNewAppInstallInDbUser(trackingIdAndAnonymousLocal[0], trackingIdAndAnonymousLocal[1]);
                addNewAppInstallInDB(trackingIdAndAnonymousLocal[0]);
                addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)
                dispatch(toggleInstallationLegendIphone(true))
            }
        }
        closeInstallCocoparks()
    }
    return (
        <div className="install-cocoparks-container flex-center-column">
            <div style={{width: "100%"}} className="flex-flex-end-column">                
                <IoIcon.IoClose 
                    className='menu-bars' 
                    onClick={()=> {closeInstallCocoparks(); addActionToDbBasedOnElement("installExitButton", trackingIdAndAnonymousLocal, trackingSessionId)}}
                />
            </div>
            <div className="flex-center-inline" style={{margin: "10px 0 10px 0"}}>
                <img src={logo} alt="Logo" style={{width: "50px", height: "50px"}}/>
                <div style={{marginLeft: "5%"}}>
                    <p className="time-to-park-text no-margin">Cocoparks</p>
                    <p className="main-install-text no-margin">Installer Cocoparks sur mon smartphone en 1 clic</p>
                </div>
            </div>
            <div style={{width: "100%"}} className="flex-flex-end-column">
                <button className="green-action-button" style={{marginTop: "10px"}} onClick={()=> {installRequest(); addActionToDbBasedOnElement("dl_app", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                    <span className="green-action-button-text">Installer</span>
                </button>
            </div>
        </div>
    )
}

export default InstallCocoparks
