import React from 'react';
import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { cocoLoaderStateSelector } from './state/cocoLoaderStateSelector';

//STYLE
import './CocoLoader.css'

function CocoLoader() {

    const cocoLoaderState = useSelector(cocoLoaderStateSelector)
    
    let loader = cocoLoaderState.map((item, key) => {
        if(item){
            return (
                <img key={key} src='../images/Markers/LOGO-small.png' alt="Logo" className='loader-coco'/>
            )
        }else {
            return (
                <img key={key} src='../images/Markers/LOGO-small-grey.png' alt="Logo" className='loader-coco'/>
            )
        }
    })

    return <>
        {loader}
    </>;
}

export default CocoLoader;
