import React from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//UTILS
import {goToParking} from '../global/MapUtils';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './ButtonGoPopup.css'
import { ampParkingEntry } from '../global/globalVariable';


export default function ButtonGoPopup(props) {

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    return (
        <div className="popup" onClick={()=> {props.setTrigger(false); addActionToDbBasedOnElement("freezePanelButtonGo", trackingIdAndAnonymousLocal, trackingSessionId)}}>
        <div className="popup-inner" style={{width: "60%"}}> 
            <div className="appSelectPanel">
                    <div className="appSelectPanelTitle"><p style={{margin: "3px"}}><strong>Ouvrir avec :</strong></p></div>
                    <table className="appTable">
                        <tbody>
                            <tr className="appTableLine" style={{cursor: "pointer"}} onClick={()=> {props.source === "amp"? goToParking("google", props.destination, ampParkingEntry) : goToParking("google", props.destination); addActionToDbBasedOnElement("googleMapsApp", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                                <td style={{textAlign: "center"}}><img style={{cursor: "pointer"}} src="https://img.icons8.com/color/96/000000/google-maps.png" alt=""/></td>
                                <td><p className="nameApp">Google Maps</p></td>
                            </tr>
                            <tr className="appTableLine" style={{cursor: "pointer"}} onClick={()=> {goToParking("waze", props.destination); addActionToDbBasedOnElement("wazeApp", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                                <td style={{textAlign: "center"}}><img style={{cursor: "pointer"}} src="https://img.icons8.com/color/96/000000/waze.png" alt=""/></td>
                                <td><p className="nameApp">Waze</p></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="closeAppSelectPanel" style={{cursor: "pointer"}}><p style={{margin: "2px"}}>Annuler</p></div>
            </div>
        </div>
    </div>
    )
}
