import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'


//REDUX SELECTOR
import { lvzMarkerDataSelector } from '../markers/state/lvzMarkerDataSelector'
import { lvzMarkerRealTimeSelector } from '../markers/state/lvzMarkerRealTimeSelector'
import { filterModeActiveSelector } from '../filters/state/filterModeActiveSelector';
import { mapFilterTrackersSelector } from '../filters/state/mapFilterTrackersSelector';
import { displayLvzSpecificDetailPanelSelector } from '../detailsPanel/state/displayLvzSpecificDetailPanelSelector';


//COMPONENTS
import LiveViewZoneMarker from './LiveViewZoneMarker'

//UTILS
import * as global from "../global/globalVariable"
import { findStringInArray } from "../global/GlobalUtils"

function WrapperLiveViewZone({map}) {

    const lvzMarkerData = useSelector(lvzMarkerDataSelector)
    const lvzMarkerRealTime = useSelector(lvzMarkerRealTimeSelector)
    const filterModeActive = useSelector(filterModeActiveSelector)
    const mapFilterTrackers = useSelector(mapFilterTrackersSelector)
    const displayLvzSpecificDetailPanel = useSelector(displayLvzSpecificDetailPanelSelector)

    let filterTrackersIdentification = global.filterTrackersIdentification;

    const markers = useMemo(() => {
        return Object.keys(lvzMarkerData).map((marker, index) =>{
            let position = {lat: lvzMarkerData[marker].location.lat, lng: lvzMarkerData[marker].location.lng}
            let parkingEntranceLocation
            let parkingExitLocation
            if(lvzMarkerData[marker].entrances_locations){
                parkingEntranceLocation = lvzMarkerData[marker].entrances_locations
            }
            if(lvzMarkerData[marker].exits_locations){
                parkingExitLocation = lvzMarkerData[marker].exits_locations
            }
            if(lvzMarkerRealTime[marker]){
                let availability = lvzMarkerRealTime[marker].availability
                //turn all the other lvz to grey if the lvz specific details panel is open
                if(displayLvzSpecificDetailPanel.value && displayLvzSpecificDetailPanel.lvzId !== marker){
                    availability = "minimized"
                }
                let freeSlot = lvzMarkerRealTime[marker].free_slots
                let activated = lvzMarkerRealTime[marker].activated
                if(filterModeActive){
                    let activeFilter = []
                    for(const [index, status] of mapFilterTrackers.entries()){
                        let isFilterForLvz = global.filterToExcludedFromLvz.find(e => e === filterTrackersIdentification[index])
                        //Push only filters corresponding to lvz in activeFilter
                        if(status && !isFilterForLvz){
                            activeFilter.push(filterTrackersIdentification[index])
                        }
                    }

                    if(activeFilter.length > 0){
                        freeSlot = 0
                        //to update liveview zone marker only if the lvz is active
                        if(lvzMarkerRealTime[marker].activated){
                            for(const slotType of Object.keys(lvzMarkerRealTime[marker].occupancy_by_type)){
                                if(findStringInArray(activeFilter, slotType)){
                                    freeSlot = freeSlot + lvzMarkerRealTime[marker].free_slots_by_type[slotType]
                                }
                            }
                        }

                        //When the filters are active, if the available slot number is 0, 
                        //then we set the availability to low to display a red marker with a cross
                        if(freeSlot === 0){
                            availability = "low"
                        }
                    }
                    
                }
                return (
                    <LiveViewZoneMarker
                        position={position}
                        availability={availability}
                        freeSlot={freeSlot}
                        key={marker}
                        path={lvzMarkerData[marker].polygon}
                        zoneId={marker}
                        activated={activated}
                        map={map}
                        entityType={lvzMarkerData[marker].entity_type}
                        parkingEntranceLocation={parkingEntranceLocation}
                        parkingExitLocation={parkingExitLocation}
                        marker={marker}
                    />
                )
            }
            return null;
        });
    }, [ lvzMarkerData, lvzMarkerRealTime, filterModeActive, mapFilterTrackers, displayLvzSpecificDetailPanel, map])

    return (
        <div>
            {markers}
        </div>
    )
}

export default WrapperLiveViewZone
