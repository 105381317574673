import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

//SOCKET IO
// import socket from '../socketio/Socket';

//REDUX SELECTOR
import { userUidSelector } from '../global/state/userUidSelector';
import { sparkedModeSelector } from '../global/state/sparkedModeSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleConfirmationLeavingSpot } from './state/displayConfirmationLeavingSpotAction'
import { toggleSparkedMode } from '../global/state/sparkedModeAction';
import { hideSparkedMarker } from '../markers/state/sparkedMarkerAction'
import { resetPathToDestination } from '../markers/state/directionToDestinationAction'
import { resetParkingTableInfo } from './state/parkingTagTableAction'
import { updateSpotScreenshot } from './state/screenshotImageSpotAction'

//UTILS
import { updateParkingHistory } from '../api/userApi'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'


function ConfirmationLeavingSpot() {

    const dispatch = useDispatch()

    const userUid = useSelector(userUidSelector)
    const sparkedMode = useSelector(sparkedModeSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    function confirmDeactivateSparkedMode(){
        dispatch(toggleConfirmationLeavingSpot(false))
        dispatch(toggleSparkedMode(false))
        dispatch(hideSparkedMarker())
        dispatch(resetPathToDestination())
        dispatch(resetParkingTableInfo())
        //to remove picture from store
        dispatch(updateSpotScreenshot(""))
        //to update lastSparkedStatus in LS and set it to false
        updateLS(false)
        //to update DB with ending time
        endingParkingHistory()
    }
    
    function cancelDeactivateSparkedMode(){
        dispatch(toggleConfirmationLeavingSpot(false))
    }

    // function sendFreedSpot(info){
    //     socket.emit('freed spot', info);
    // }
    
    function updateLS(status){
        if(userUid != null && userUid !== ""){
            let lsuid = JSON.parse(localStorage.getItem(userUid));
            if(lsuid != null){
                lsuid['lastSparkedStatus'] = status;
                //to remove picture of the spot from LS
                delete lsuid['lastSparkedData'].picture
                localStorage.setItem(userUid, JSON.stringify(lsuid));
                let info = {
                    position: lsuid.lastSparkedData.position,
                    start: Date.now(),                    
                }
                //To send information about freeing a slot to socketio only if the parking type was on-street
                // if(sparkedMode.type === "onstreet"){
                //     sendFreedSpot(info)
                // }
            }
        }
    };


    function endingParkingHistory(){
        let lsuid = JSON.parse(localStorage.getItem(userUid));
        if(lsuid != null){
            let parkingHistId = lsuid.parkingHistId
            // update field for completed spark status in parking history
            let end = new Date();
            let endOfParkingMsg = {
                "end": end,
                "parkingHistId": parkingHistId
            }
            updateParkingHistory(endOfParkingMsg);
        }
    }

    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column" >
            <p className="time-to-park-text"> Confirmez-vous quitter votre stationnement actuel ?</p>
            <div className="time-park-button-div">
                <button className="green-button-time-to-park" onClick={()=> {confirmDeactivateSparkedMode(); addActionToDbBasedOnElement("leaveParkButtonConfirm", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">OK</p></button>
            </div>
            <div className="time-park-button-div">
                <button className="green-button-time-to-park" onClick={()=> {cancelDeactivateSparkedMode(); addActionToDbBasedOnElement("leaveParkButtonCancel", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">ANNULER</p></button>
            </div>
        </div>
    )
}

export default ConfirmationLeavingSpot
