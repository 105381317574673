//all companies names to update marker icon
export const companyMarkers = [
    "EFFIA",
    "INDIGO",
    "SAEMES",
    "INTERPARKING",
    "PARCUS",
    "Q PARK",
    "QPARK",
    "VINCI",
    "OPNGO",
    "ZENPARK",
    "ONEPARK",
    "effia",
    "indigo",
    "saemes",
    "interparking",
    "parcus",
    "q park",
    "qpark",
    "vinci",
    "opngo",
    "zenpark",
    "onepark"
];

export const liveParkingsColor = ["green", "orange", "red"];
export const liveParkingsThreshold = [0,0.75,0.85,1.01];
export const filterTrackersIdentification = ["carwash", "booking", "evh", "pmr", "delivery"]
//All filters that do not impact lvz
export const filterToExcludedFromLvz = ["carwash", "booking"]

export const existingPaymentApps = ['Paybyphone', 'Whoosh', 'Parknow', 'Easypark', 'Opngo'];
export const existingPaymentAppsURLs = [
    'https://m2.paybyphone.fr/',
    'https://www.whooshstore.fr/',
    "https://fr.park-now.com/",
    "https://easypark.fr/fr",
    "https://www.opngo.com/fr/page/se-garer-avec-opngo#se-garer-rue",
];
export const cityToPaymentApps = {
    "bordeaux": [3],
    "caen": [0],
    "dijon": [0,1],
    "grenoble": [2],
    "lille": [1],
    "lyon": [0],
    "malakoff": [0],
    "marseille": [0,1],
    "montpellier": [1],
    "nantes": [0],
    "nice": [0],
    "paris": [0,1,2],
    "rennes": [1],
    "saint-denis": [0],
    "sceaux": [1],
    "sens": [0],
    "strasbourg": [1,3,4],
    "toulon": [0],
    "toulouse": [2],
    "vincennes": [2],
}

export const lvzThreshold = [0,0.90,0.98,1.01];
export const liveLvzColor = ["green", "orange", "red"];

export const lvzPolygonColor = {
            red: {strokeColor: "#E32C2C", fillColor: "#E32C2C"}, 
            orange: {strokeColor: "#FFBD07", fillColor: "#FFBD07"}, 
            green: {strokeColor: "#1faa00", fillColor: "#1faa00"},
            grey: {strokeColor: "#808080", fillColor: "#808080"} 
        }

export const lvzColors = {
    "high": {strokeColor: "#1faa00", fillColor: "#1faa00", zIndex: 3, pictureKey: "green", cssColorName: "var(--cocoparksGreen)"},
    "medium": {strokeColor: "#FFBD07", fillColor: "#FFBD07", zIndex: 2, pictureKey: "orange", cssColorName: "var(--filling-orange)"},
    "low": {strokeColor: "#E32C2C", fillColor: "#E32C2C", zIndex: 1, pictureKey: "red", cssColorName: "var(--filling-red)"},
    "deactivate": {strokeColor: "#808080", fillColor: "#808080", zIndex: 1, pictureKey: "grey", cssColorName: ""},
    "minimized": {strokeColor: "#CCCCCC", fillColor: "#CCCCCC", zIndex: 1, pictureKey: "grey-disabled", cssColorName: ""},
}

export const slotTypeArray = ["pmr", "delivery", "delivery_only", "delivery_dotted", "temp_only_20min", "scooter", "forbidden", "bike", "short_duration", "evh"]

export const slotTypeKey = {
    "pmr": "Place PMR",
    "delivery": "Place de livraison",
    "delivery_only": "Place de livraison uniquement", 
    "delivery_dotted": "Place de livraison pointillé", 
    "temp_only_20min": "Place arrêt minute",
    "scooter": "Place Scooter",
    "forbidden": "Emplacement interdit",
    "bike": "Place vélo",
    "short_duration": "Place arrêt minute",
    "evh": "Place véhicule électrique", 
    "common": "Place Classique",
}

export const timeIndexConversionToString = ["<5", "5-10", "10-15", "15-20", ">20"];

export const maxNbOfCardInExpandedDetailPanel = 10

export const cocoparksVersionNumber = "2.0.1.3"

export const ampParkingEntry = {lat: 43.446742246639026, lng:5.222079328868169}