
import React from 'react'
import { useSelector } from 'react-redux'

//COMPONENTS
import DottedDirectionPolyline from '../global/DottedDirectionPolyline'

//REDUX SELECTOR
import { closestLvzPathsSelector } from '../detailsPanel/state/closestLvzPathsSelector'
import { displayLvzSpecificDetailPanelSelector } from '../detailsPanel/state/displayLvzSpecificDetailPanelSelector';

//UTILS
import * as global from "../global/globalVariable"

function WrapperLvzDirections(){

    const closestLvzPaths = useSelector(closestLvzPathsSelector)
    const displayLvzSpecificDetailPanel = useSelector(displayLvzSpecificDetailPanelSelector)

    return(
        <div>
        {closestLvzPaths.map((lvzItinerary, index) => {
            let availability = lvzItinerary.availability
            if(displayLvzSpecificDetailPanel.value && displayLvzSpecificDetailPanel.lvzId !== lvzItinerary.lvzId){
                availability = "minimized"
            }
            return(
                <DottedDirectionPolyline 
                    key={index}
                    path={lvzItinerary.path}
                    strokeColor={global.lvzColors[availability].strokeColor}
                    fillColor={global.lvzColors[availability].fillColor}
                    zIndex={global.lvzColors[availability].zIndex}
                />
            )
        })}
        </div>
    )

}

export default WrapperLvzDirections