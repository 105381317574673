import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

//FIREBASE
import firebase from '../firebaseConfig';
import { startFirebaseUI } from '../firebaseConfig'

//COMPONENTS
import MenuUserConnected from './MenuUserConnected';

//REDUX SELECTOR
import { displayUserMenuSelector } from './state/displayUserMenuSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector';

//REDUX ACTIONS
import { toggleUserMenu } from './state/displayUserMenuAction ';
import { updateUserUid } from '../global/state/userUidAction';
import { updateUserInfoJson } from './state/userInfoJsonAction';
import { resetUserInfoJson } from './state/userInfoJsonAction';
import { updateTrackingSessionId } from '../userTracking/state/trackingSessionIdAction';
import { updateTrackingData } from '../userTracking/state/trackingIdAndAnonymousLocalAction';

//UTILS
import { checkIfUserExistOnDB, getUser, getAnonymousUser} from '../api/authApi';
import { updateUserLogInOut, checkIfIsAnonymousUser, createUser, addAnonymousUserToLS } from '../global/UserMenuUtils'
import { createUserTrackingSession } from '../global/UserTrackingUtils'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'
import { updateUserVersion } from '../api/userApi'
import * as global from '../global/globalVariable'

//STYLE
import './UserMenu.css'
import { IconContext } from 'react-icons';
import logo from '../images/logo.png'
import * as AiIcon from 'react-icons/ai';
import * as IoIcon from 'react-icons/io5';


function UserMenu(props) {

    let uid
    let lsAnonymousId
    let cocoparksVersionNumber = global.cocoparksVersionNumber 

    const dispatch = useDispatch()

    const displayUserMenu = useSelector(displayUserMenuSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const [user, setUser] = useState(null);

    function toggleUserMenuOnRight(status){
        dispatch(toggleUserMenu(status))
    }

    const userMenuStarText = [
        "Gagnez des points en contribuant", 
        "Retrouvez votre historique de stationnement", 
        "Obtenenez des conseils pour réduire vos dépenses"
    ]

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async function(user){
            setUser(user);
            if(user){
                let displayName = user.displayName;
                let email = user.email;
                let photoURL = user.photoURL;
                let isAnonymous = user.isAnonymous;
                uid = user.uid
                //to store user uid, used to call backend (history, update...) 
                dispatch(updateUserUid(uid))
                await checkIfUserExistOnDB(uid)
                .then(async function(resp){
                    if(resp === "1"){
                        let userFromDb = await getUser(uid)
                        //if user app version from DB is different from the actual one
                        //then update user version in DB
                        if(userFromDb.appVersion !== cocoparksVersionNumber){
                            let versionDataToSave = {
                                "userId": uid, // userid details for identification defined in auth.js, if anynomous then anonymous
                                "updates": {"appVersion": cocoparksVersionNumber},
                                "is_anonymous": false
                            }
                            await updateUserVersion(versionDataToSave, uid)
                        }
                        dispatch(updateUserInfoJson({...userFromDb, uid: uid}))
                        // udpate firestore with user info -> connection time
                        updateUserLogInOut(uid, 'logInTime', false);
                    } else {
                        lsAnonymousId = await checkIfIsAnonymousUser();
                        let profileData = {
                            'firstName': "",
                            'lastName': "",
                            'displayName': displayName,
                            'email': email,
                            'photoURL': photoURL,
                            'birthdate': "",
                            'creationTime': new Date(Date.now()),
                            'isAnonymous': isAnonymous,
                            'isAnonymousLocal': false,
                            'isAnonymousId': lsAnonymousId,
                            'appVersion': cocoparksVersionNumber
                        };
                        await createUser(uid, profileData)
                        .then(async function(){
                            let userFromDb = await getUser(uid)
                            dispatch(updateUserInfoJson({...userFromDb, uid: uid}))
                        })
                    }
                    // Get Id and AnonymousLocal for userTracking purpose
                    let trackingIdAndAnonymousLocal = getIdAndAnonymousLocal();
                    dispatch(updateTrackingData(trackingIdAndAnonymousLocal))
                    // Get sessionName for userTracking purpose
                    let trackingSession = await createUserTrackingSession(trackingIdAndAnonymousLocal);
                    dispatch(updateTrackingSessionId(trackingSession))
                })
            } else {
                uid = null
                lsAnonymousId = await checkIfIsAnonymousUser();
                if(lsAnonymousId !== "none"){
                    let anonymousUserFromDb = await getAnonymousUser(lsAnonymousId)
                    if(anonymousUserFromDb && anonymousUserFromDb.appVersion !== cocoparksVersionNumber){
                        let versionDataToSave = {
                            "userId": lsAnonymousId, // userid details for identification defined in auth.js, if anynomous then anonymous
                            "updates": {"appVersion": cocoparksVersionNumber},
                            "is_anonymous": true
                        }
                        await updateUserVersion(versionDataToSave, lsAnonymousId)
                    }
                    // udpate firestore with user info -> connection time
                    updateUserLogInOut(lsAnonymousId, 'logInTime', true);
                    // Get Id and AnonymousLocal for userTracking purpose
                    let trackingIdAndAnonymousLocal = getIdAndAnonymousLocal();
                    dispatch(updateTrackingData(trackingIdAndAnonymousLocal))
                    // Get sessionName for userTracking purpose
                    let trackingSession = await createUserTrackingSession(trackingIdAndAnonymousLocal);
                    dispatch(updateTrackingSessionId(trackingSession))
                }else{
                    // create anonymous user in databse
                    var profileData = {
                        'creationTime': new Date(Date.now()),
                        'isAnonymousLocal': true,
                        'appVersion': cocoparksVersionNumber
                    };
                    await createUser('', profileData)
                    .then(async function(newId){
                        // then add ID in Local Storage
                        addAnonymousUserToLS(newId);
                        let trackingIdAndAnonymousLocal = [newId, true]
                        dispatch(updateTrackingData(trackingIdAndAnonymousLocal))
                        // Get sessionName for userTracking purpose
                        let trackingSession = await createUserTrackingSession(trackingIdAndAnonymousLocal);
                        if (trackingSession) {
                            dispatch(updateTrackingSessionId(trackingSession));
                        } else {
                            // if it failed to create tracking session
                            dispatch(updateTrackingSessionId(null)); 
                        }
                    })
                }
                //to remove uid from store if user isn't connected or disconnect
                dispatch(updateUserUid(null))
                dispatch(resetUserInfoJson())
            }
        })
        startFirebaseUI('#firebaseui')
    }, [dispatch])

    /**
     * Fetches User UID and if he's an anonymous user or not
     * @returns {Array} - Array made by userID, isAnonymousLocal
     */
    function getIdAndAnonymousLocal(){
        let idTrackingToSend = "";
        let isAnonymousLocal="";
        if(uid){
            idTrackingToSend = uid;
            isAnonymousLocal = false;
        } else {
            isAnonymousLocal = true;
            idTrackingToSend = lsAnonymousId;
        }
        let res = [idTrackingToSend, isAnonymousLocal];
        return res;
    }

    return (
        <>
            <IconContext.Provider value={{ color: '#ffc107' }}>
                <div className={displayUserMenu ? 'nav-user-menu active flex-center-column' : 'nav-user-menu flex-center-column'}>
                    <button 
                        style={{borderStyle : "none", background: "none", padding: "0px", position: "absolute", top: "10px", left: "10px", height: "40px", width: "40px"}} 
                        onClick={()=> {toggleUserMenuOnRight(false); addActionToDbBasedOnElement("closeLoginNav", trackingIdAndAnonymousLocal, trackingSessionId)}}
                    >
                        <IoIcon.IoChevronBack style={{color: "black", height: "30px", width: "30px"}}/>
                    </button>
                { user ? <MenuUserConnected map={props.map}/>
                :<div className="flex-center-column">
                    <img src={logo} alt="Logo" style={{height: "100px", marginTop: "24px"}}/>
                    <h2 className="title">Bienvenue sur Cocoparks</h2>
                    <div style={{margin: "10px"}}>
                        {userMenuStarText.map((item, index) => {
                            return (
                                <div className="flex-align-top-inline" style={{textAlign: "center", margin: "5px"}} key={index}>
                                <AiIcon.AiFillStar/>
                                <p style={{fontSize: "0.8em", marginBottom: "0px"}} >{item}</p>
                                <AiIcon.AiFillStar/>
                                </div>
                            );
                        })}
                    </div>
                    <div id="firebaseui">
                    </div>
                </div>
                }
                </div>
            </IconContext.Provider>
            {displayUserMenu ? 
                <div className="freeze-background active freeze-background-menu" 
                    onClick={()=>{toggleUserMenuOnRight(false); addActionToDbBasedOnElement("freezePanelLoginNav", trackingIdAndAnonymousLocal, trackingSessionId)}}
                ></div>
            : <div className="freeze-background"></div>}
        </>
    )
}

export default UserMenu;