import React from "react";
import { useSelector } from "react-redux";
import { Polygon } from "@react-google-maps/api";

//REDUX SELECTOR
import { frontStyleLvzSelector } from "./state/frontStyleLvzSelector";

//UTILS
import * as global from "../global/globalVariable";
import { zoomOnClickedLvz, displayLvzDetailPanel } from "../global/LvzUtils";

function LiveViweZoneMarkerPolygon({
  path,
  availability,
  map,
  position,
  zoneId,
}) {
  const frontStyleLvz = useSelector(frontStyleLvzSelector);

  let visible = true;

  if (frontStyleLvz === 3) {
    visible = true;
  } else {
    visible = false;
  }

  const options = {
    fillColor: global.lvzColors[availability].fillColor,
    fillOpacity: 0.4,
    strokeColor: global.lvzColors[availability].strokeColor,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    zIndex: 1,
    visible: visible,
  };

  function clickOnLvzMarker() {
    // zoomOnClickedLvz(map, position)
    displayLvzDetailPanel(zoneId);
  }

  return <Polygon paths={path} options={options} onClick={clickOnLvzMarker} />;
}

export default LiveViweZoneMarkerPolygon;
