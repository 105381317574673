import { handleResponse, transfromObjectToQueryString } from "./utilsApi"
import * as url from './routes'

/**
 * to get liveview zone data
 * @param {object} position - {lat: float, lng: float}
 * @param {array} loadedLvz - array containing all the id of the lvz already loaded
 */
export function getLvzStaticAndDynamicData(position, loadedLvz){
    return new Promise((resolve, reject)=>{        
        let dataToSend = transfromObjectToQueryString({
            lat: position.lat,
            lng: position.lng,
            loaded_lvz: loadedLvz
        });
        fetch(url.liveviewData + dataToSend)
        .then(handleResponse)
        .then(data => {
            resolve(data)
        })
        .catch(err=> resolve("error"))
    })
}

/**
 * to get liveview zone data
 * @param {object} position - {lat: int, lng: int}
 * @param {array} loadedLvz - array containing all the id of the lvz already loaded
 */
export function getLvzStaticData(position, loadedLvz){
    let loadedLvzToSend = []
    if(loadedLvz){
        loadedLvzToSend = loadedLvz
    }
    let dataToSend = {
        "location": {
          "lat": position.lat,
          "lng": position.lng
        },
        "loaded_lvz": loadedLvzToSend
    };
    var response = fetch(url.liveviewStaticData, {
            method: 'post',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })
        .then(handleResponse)
        .then(data => {
            return data.results
        })
        .catch(error => console.log(error))
    return response
} 

/**
 * to get liveview zone dynamic data
 * @param {object} position - {lat: int, lng: int}
 * @param {array} loadedLvz - array containing all the id of the lvz already loaded
 */
export function getLvzDynamicData(position, loadedLvz){
    let loadedLvzToSend = []
    if(loadedLvz){
        loadedLvzToSend = loadedLvz
    }
    let dataToSend = {
        "location": {
          "lat": position.lat,
          "lng": position.lng
        },
        "loaded_lvz": loadedLvzToSend
    };
    var response = fetch(url.liveviewDynamicData, {
            method: 'post',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })
        .then(handleResponse)
        .then(data => {
            return data.results
        })
        .catch(error => console.log(error))
    return response
} 


/**
 * get closest available liveview zone 
 * exists key will be false if there isn't any lvz 
 * in perimeter of 600m
 * @param {object} location - {lat: int, lng: int}
 */
 export function getClosestAvailableLvz(location){
    return new Promise((resolve, reject)=>{        
        let dataToSend = transfromObjectToQueryString({
            lat: location.lat,
            lng: location.lng,
        });
        fetch(url.closestAvailableLvz + dataToSend)
        .then(handleResponse)
        .then(data => {
            resolve(data)
        })
        .catch(err=> resolve("error"))
    })
} 


/**
 * get closest liveview info (name, eta, free_slots, availability, prices, id, location)
 * Used to display lvz card on expanded details panel 
 * @param {object} location - {lat: int, lng: int}
 */
 export function getClosestLvzInfo(location){
    return new Promise((resolve, reject)=>{        
        let dataToSend = transfromObjectToQueryString({
            lat: location.lat,
            lng: location.lng,
        });
        fetch(url.closesLvzNearby + dataToSend)
        .then(handleResponse)
        .then(data => {
            resolve({
                status : 200,
                results: data
            })
        })
        .catch(err=> resolve("error"))
    })
} 