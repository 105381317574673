import React from 'react';
import { useSelector } from 'react-redux';

//COMPONENTS
import LiveViewZoneMarkerWithArrow from './LiveViewZoneMarkerWithArrow';
import LiveViewZoneMarkerWithNumber from './LiveViewZoneMarkerWithNumber';
import LiveViweZoneMarkerPolygon from './LiveViweZoneMarkerPolygon';
import LiveViewZoneCircle from './LiveViewZoneCircle';
import WrapperLiveViewZoneSlots from './WrapperLiveViewZoneSlots';

//REDUX SELECTOR
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector';

function LiveViewZoneMarker({ position, availability, freeSlot, path, zoneId, map, entityType, parkingEntranceLocation, parkingExitLocation, activated, marker }) {
    const frontStyleLvz = useSelector(frontStyleLvzSelector);
    if(!activated){
        availability = "deactivate"
    }

    return (
        <>
            <LiveViewZoneMarkerWithArrow
                position={position}
                parkingEntranceLocation={parkingEntranceLocation}
                parkingExitLocation={parkingExitLocation}
                freeSlot={freeSlot}
                map={map}
                availability={availability}
                zoneId={zoneId}
            />
            
            {frontStyleLvz >= 4 && <WrapperLiveViewZoneSlots visible={frontStyleLvz>=4} map={map} marker={marker} activated={activated} />}

            <LiveViewZoneMarkerWithNumber
                position={position}
                freeSlot={freeSlot}
                zoneId={zoneId}
                map={map}
                availability={availability}
            />

            <LiveViweZoneMarkerPolygon
                availability={availability}
                position={position}
                path={path}
                zoneId={zoneId}
                map={map}
            />

            <LiveViewZoneCircle
                position={position}
                availability={availability}
                zoneId={zoneId}
                map={map}
            />
        </>
    );
}

export default LiveViewZoneMarker;