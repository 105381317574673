import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { customMarkersSelector } from '../markers/state/customMarkersSelector';
import CustomMarker from './CustomMarker';


function WrapperCustomMarkers({ map }) {
    const customMarkers = useSelector(customMarkersSelector);
    const markers = useMemo(() => {
        if(customMarkers.length === 0) {
            return null;
        }
        return customMarkers.map((marker) => (
            <CustomMarker
                key={marker.id }
                position={{ lat: marker.lat, lng: marker.lng }}
                iconUrl={marker.icon}
                description={marker.description}
                
            />
        ));
    }, [customMarkers, map]);

    return <div>{markers}</div>;
}

export default WrapperCustomMarkers;
