import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

//COMPONENTS
import ConfirmationLeavingSpot from '../sparkedMode/ConfirmationLeavingSpot';
import OpenPayPopup from '../popup/OpenPayPopup'
import OpenCameraPopup from '../popup/OpenCameraPopup'

//REDUX SELECTOR
import { displayConfirmationLeavingSpotSelector } from '../sparkedMode/state/displayConfirmationLeavingSpotSelector'
import { ttpInfoSelector } from '../sparkedMode/state/ttpInfoSelector'
import { userLocationSelector } from '../markers/state/userLocationSelector';
import { sparkedMarkerSelector } from '../markers/state/sparkedMarkerSelector';
import { screenshotImageSpotSelector } from '../sparkedMode/state/screenshotImageSpotSelector';
import { sparkedModeSelector } from '../global/state/sparkedModeSelector';
import { sparkedParkingDataSelector } from '../sparkedMode/state/sparkedParkingDataSelector';
import { userUidSelector } from '../global/state/userUidSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleConfirmationLeavingSpot } from '../sparkedMode/state/displayConfirmationLeavingSpotAction';
import { updatePathToDestination } from '../markers/state/directionToDestinationAction'
import { addParkingTableInfo } from '../sparkedMode/state/parkingTagTableAction'
import { addSparkedAddress } from '../sparkedMode/state/sparkedAddressAction';
import { toggleSectionDrawer } from '../navBar/state/navBarAction';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//UTILS
import { transformTimestampToDateAndTime , updateDuration } from '../global/SparkedModeUtils'
import { geocodeLatLng, getDriveTimeAndPath, getTimeAndDistanceBasedOnType} from '../global/MapUtils'
import { addNewHistory } from '../api/userApi'

//STYLE
import * as IoIcon from 'react-icons/io5';
import * as MdIcon from 'react-icons/md';
import './SparkedDetailsPanel.css'


function SparkedDetailsPanel(props){

    const dispatch = useDispatch()

    const displayConfirmationLeavingSpot = useSelector(displayConfirmationLeavingSpotSelector)
    const ttpInfo = useSelector(ttpInfoSelector)
    const sparkedMarker = useSelector(sparkedMarkerSelector)
    const userLocation = useSelector(userLocationSelector) 
    const screenshotImageSpot = useSelector(screenshotImageSpotSelector)
    const sparkedMode = useSelector(sparkedModeSelector)
    const sparkedParkingData = useSelector(sparkedParkingDataSelector)
    const userUid = useSelector(userUidSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const config = useSelector((state) => state.config)
    const IsAMPCustomer = config.customer === 'amp'

    const [sparkedDate, setSparkedDate] = useState("-")
    const [sparkedTime, setSparkedTime] = useState("-")
    const [parkingDuration, setParkingDuration] = useState("-")
    const [sparkedAddress, setSparkedAddress] = useState("-")
    const [sparkedParkingNameAndPrice, setSparkedParkingNameAndPrice] = useState({name: "-", price: {price1: "-", price3: "-", price24: "-"}})
    const [showOpenPayPopup, setShowOpenPayPopup] = useState(false)
    const [showCameraPopup, setShowCameraPopup] = useState(false)
    const [displaySpotSreenShot, setDisplaySpotSreenShot] = useState(false)
    const [imageSource, setImageSource] = useState("")


    useEffect(() => {
        //to display screen shot if picture of slot exist in LS
        let lsuid = JSON.parse(localStorage.getItem(userUid))
        if(lsuid !== null && userUid !== null){
            if(lsuid.lastSparkedData.picture !== "" && lsuid.lastSparkedData.picture !== undefined ){
                setImageSource(lsuid.lastSparkedData.picture)
                setDisplaySpotSreenShot(true)
            }
        }

        //to get date and time to be displayed on panel
        let resp = transformTimestampToDateAndTime(ttpInfo.start)
        setSparkedDate(resp.date)
        setSparkedTime(resp.time)

        if(sparkedMode.type === "onstreet"){
            getAndShowAddress(ttpInfo.position)
        } else {
            setSparkedParkingNameAndPrice({name: sparkedParkingData.name, price: sparkedParkingData.price})
            getParkingAddress(sparkedParkingData.location)
        }
        
        //to calculate parked duration (used when user reconnect)
        let initialeStateDuration = updateDuration(ttpInfo.start)
        setParkingDuration(initialeStateDuration)

        //to update duration on sparked detail panel
        const duration = setInterval(() => {
            let resp = updateDuration(ttpInfo.start)
            setParkingDuration(resp)
        }, 1000 * 60);

        // Clear timeout if the component is unmounted
        return () => clearInterval(duration);
    }, [])


    useEffect(() => {
        //to display the last screenshot of the spot if there is one
        //else displaying camera picture
        if(screenshotImageSpot !== ""){
            setDisplaySpotSreenShot(true)
            setImageSource(screenshotImageSpot)
        }
    }, [screenshotImageSpot])
    

    function deactivateSparkedMode(){
        dispatch(toggleConfirmationLeavingSpot(true))
    }


    /**
     * to get the address of the parked position
     *
     * @param {object} position - sparked position
     */
    async function getAndShowAddress(position){
        await geocodeLatLng(position)
            .then(async function(response){
                setSparkedAddress(response)
                dispatch(addSparkedAddress(response))
                addLastStatusToLS(response)
            });
    }


    /**
     * to get the address of the parked parking
     *
     * @param {object} location - sparked parking position
     */
    async function getParkingAddress(location){
        await geocodeLatLng(location)
        .then(async function(response){
            dispatch(addSparkedAddress(response))
            addLastParkingStatusToLS(sparkedParkingData, response)
        });
    }


    /**
     * updates uid of local storage with the details that can be retrieved if user logs out
     *
     */
    async function addLastStatusToLS(address){
        let statusToSave = {'sparked_status': true,};
        let sparkedDataToSave = {
            "userId": userUid, // userid details for identification defined in auth.js, if anynomous then anonymous
            "position": ttpInfo.position,
            "ptype": "onstreet", // offstreet or onstreet
            "pname": "", //parking name
            "pcompany": "", // shows company if offstreet parking
            "start": new Date(ttpInfo.start), //date and time of start of parking
            "end": "0", //date and time of start of parking
            "postcode": address.postcode,
            "address": address.fulladdress, // address of streetparking of or offstreet parking
            "cost": "unknown", // could be updated later on
            "ttp": ttpInfo.ttp,
            "startTimestamp": ttpInfo.start,
            "picture": screenshotImageSpot
        }
        localStorageWithSparkedData(statusToSave, sparkedDataToSave)
        let resp = await addNewHistory(sparkedDataToSave)
        updateLocalStorageWithParkingHistId(resp)
    }


    /**
     * updates uid of local storage with the details that can be retrieved if user logs out
     *
     */
    async function addLastParkingStatusToLS(parking, res){
        let statusToSave = {'sparked_status': true,};
        let sparkedDataToSave = {
            "userId": userUid, // userid details for identification defined in auth.js, if anynomous then anonymous
            "position": parking.location,
            "ptype": "offstreet", // offstreet or onstreet
            "pname": parking.name, //street name or parking name
            "pcompany": parking.company, // shows company if offstreet parking
            "start": new Date(ttpInfo.start), //date and time of start of parking
            "end": "0", //date and time of start of parking
            "postcode": res.postcode,
            "address": res.fulladdress, // address of streetparking of or offstreet parking
            "cost": "unknown", // could be updated later on
            "ptarifstruct": {
                "price1": parking.price.price1,
                "price3": parking.price.price3,
                "price24": parking.price.price24
            },
            "startTimestamp": ttpInfo.start,
            "picture": screenshotImageSpot
        }
        localStorageWithSparkedData(statusToSave, sparkedDataToSave)
        let resp = await addNewHistory(sparkedDataToSave)
        updateLocalStorageWithParkingHistId(resp)
    }


    function localStorageWithSparkedData(statusToSave, sparkedDataToSave){
        if(userUid != null && userUid !== ""){
            var lsuid = JSON.parse(localStorage.getItem(userUid));
            if(lsuid != null){
                lsuid['lastSparkedStatus'] = statusToSave.sparked_status;
                lsuid['lastSparkedData'] = sparkedDataToSave;
                localStorage.setItem(userUid, JSON.stringify(lsuid));
            }else{
                localStorage.setItem(userUid, JSON.stringify({
                    'lastSparkedStatus': statusToSave.sparked_status,
                    'lastSparkedData': sparkedDataToSave,
                }));
            }
        }
    }


    function updateLocalStorageWithParkingHistId(resp){
        let lsuid = JSON.parse(localStorage.getItem(userUid))
        if(lsuid != null){
            lsuid = {...lsuid, parkingHistId: resp}
            localStorage.setItem(userUid, JSON.stringify(lsuid)); // update the localStorage
        }
    }


    //to display direction from userLocation to sparkedMarker. Launch when user click on "retour"
    async function getBackToParking(){
        let timeAndDistance = {}
        await getDriveTimeAndPath(userLocation, sparkedMarker.position)
        .then(function(response){
            let destination = { lat: sparkedMarker.position.lat, lng: sparkedMarker.position.lng }
            props.map.panTo(destination)
            dispatch(updatePathToDestination({path: response.drivePath, style: "dot"}))
            setTimeout(async function(){
                await getTimeAndDistanceBasedOnType(userLocation, sparkedMarker.position, "DRIVING")
                .then(async function (resp){
                    timeAndDistance.driveTime = resp[0]
                    timeAndDistance.driveDist = resp[1]
                    setTimeout(async ()=>{
                        await getTimeAndDistanceBasedOnType(userLocation, sparkedMarker.position, "WALKING")
                        .then(function(resp){
                            timeAndDistance.walkTime = resp[0]
                            timeAndDistance.walkDist = resp[1]
                            timeAndDistance.visible =  true
                            dispatch(addParkingTableInfo(timeAndDistance))
                        })
                    }, 1000)
                })
            }, 1000)
        })

    }


    //to show the sharingApp compoment (the one with the differents app to share Cocoparks with)
    function displayShareAppComponent(){
        dispatch(toggleSectionDrawer("share", true))
    }


    return (
        <>
            <div className='sparked-detail-panel-container flex-center-column'>
                <div className="close-sparked-overview-div" onClick={()=>{deactivateSparkedMode()}}>
                    <button className="city-map-button-green"
                        onClick={()=>addActionToDbBasedOnElement("getBackOnTheRoad", trackingIdAndAnonymousLocal, trackingSessionId)}
                    >
                        <div className="city-map-button-container-green">
                            <span>Reprendre la route</span>
                            <IoIcon.IoChevronForward style={{color: 'white',height: "24px", width: "24px"}}/>
                        </div>
                    </button>
                </div>
                {/* div croix verte */}
                <div onClick={()=>{deactivateSparkedMode(); addActionToDbBasedOnElement("close_sparked_status", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                    <IoIcon.IoClose 
                        style={{color: "white", width: "20px", height: "20px"}}
                    />
                </div>
                <div style={{width: "100%"}}>
                    <div className="pid">
                        <div className="pid-section pname-div"><strong>Garé au: <span>{sparkedMode.type === "onstreet" ? sparkedAddress.fulladdress: "PARKING " + sparkedParkingNameAndPrice.name}</span></strong></div>
                    </div>
                    <div className="pinfo">
                        <div className="s-pinfo-section" id="keyUpdates">
                            <li><span>Durée: </span><strong>{parkingDuration}</strong></li>
                            <li><span>Garé à: </span><strong>{sparkedTime}</strong></li>
                            <li><span style={{fontWeight: "700"}}>{sparkedDate}</span></li>
                        </div>
                        {IsAMPCustomer ? null :
                            <div className="s-pinfo-section" style={{minWidth: "65px"}}>
                                <li><span>1h: </span><strong>{sparkedMode.type === "offstreet" && sparkedParkingNameAndPrice.price.price1 != null ? sparkedParkingNameAndPrice.price.price1 : "-"}</strong><strong color="white">&euro;</strong></li>
                                <li><span>3h: </span><strong>{sparkedMode.type === "offstreet" && sparkedParkingNameAndPrice.price.price3 != null ? sparkedParkingNameAndPrice.price.price3 : "-"}</strong><strong color="white">&euro;</strong></li>
                                <li><span>24h: </span><strong>{sparkedMode.type === "offstreet" && sparkedParkingNameAndPrice.price.price24 != null ? sparkedParkingNameAndPrice.price.price24 : "-"}</strong><strong color="white">&euro;</strong></li>
                            </div>
                        }
                        {displaySpotSreenShot ? 
                            <div className="s-pinfo-section" style={{minWidth: "70px", display: "flex", justifyContent: "center", alignItems: "center"}} onClick={()=>setShowCameraPopup(true)}>
                                <img className="screenshot-img-saved" src={imageSource} alt=""/>
                            </div>:<div className="s-pinfo-section" onClick={()=> {setShowCameraPopup(true); addActionToDbBasedOnElement("capture-button", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                                <MdIcon.MdCameraAlt className="capture-button"/>
                            </div>
                        }
                    </div>
                    <div className="pactions" text-align="center">
                        <button className="s-pactions-button" onClick={()=> {getBackToParking(); addActionToDbBasedOnElement("buttonGetBack", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                            <MdIcon.MdNavigation className="pactions-icons"/>
                            Retour
                        </button>
                        {sparkedMode.type === "onstreet" && !IsAMPCustomer? 
                            <button className="s-pactions-button" onClick={()=> {setShowOpenPayPopup(true); addActionToDbBasedOnElement("buttonPay", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                                <MdIcon.MdCreditCard className="pactions-icons"/>
                                Payer
                            </button>:null
                        }
                        <button className="s-pactions-button" onClick={()=> {displayShareAppComponent(); addActionToDbBasedOnElement("buttonShare", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                            <MdIcon.MdChatBubbleOutline className="pactions-icons"/>
                            Partager
                        </button>
                    </div>
                </div>
            </div>
            {displayConfirmationLeavingSpot ? <ConfirmationLeavingSpot /> : null}
            {showOpenPayPopup ? <OpenPayPopup setTrigger={setShowOpenPayPopup}/> : null}
            {showCameraPopup ? <OpenCameraPopup setTrigger={setShowCameraPopup}/> : null}
        </>
    
    )
}

export default SparkedDetailsPanel
