export const UPDATE_CUSTOM_MARKERS = 'UPDATE_CUSTOM_MARKERS';


const customMarkersReducer = (state = [], action) => {
    switch (action.type) {
        case UPDATE_CUSTOM_MARKERS:
            return action.payload ;
        default:
            return state;
    }
};

export default customMarkersReducer;