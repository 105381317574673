import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

//COMPONENTS
import LvzInfoWindow from './LvzInfoWindow';
import LiveViewZoneSlotMarker from './LiveViewZoneSlotMarker';

//UTILS
import { slotTypeKey } from '../global/globalVariable';
import { debounce } from '../utils'; // Import debounce utility

//REDUX SELECTOR
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector';
import { lvzMarkerRealTimeSelector } from '../markers/state/lvzMarkerRealTimeSelector';
import { lvzInfoWindowSelector } from './state/lvzInfoWindowSelector';

const WrapperLiveViewZoneSlots = React.memo(({ visible, map , marker, activated}) => {
    const frontStyleLvz = useSelector(frontStyleLvzSelector);
    const lvzMarkerRealTime = useSelector(lvzMarkerRealTimeSelector);
    const lvzInfoWindow = useSelector(lvzInfoWindowSelector);
    const [bounds, setBounds] = useState(null);


    // Track if the component is mounted
    const isMounted = useRef(true);

    const updateBounds = useCallback(() => {
        if (isMounted.current && visible) {
            setBounds(map.getBounds());
        }
    }, [map, visible]);

    useEffect(() => {
        if (map) {
            const debounceWait = visible ? 200 : 0;
            const debouncedUpdateBounds = debounce(updateBounds, debounceWait);

            const listener = map.addListener('bounds_changed', () => {
                if (isMounted.current) {
                    debouncedUpdateBounds();
                }
            });

            if (isMounted.current) {
                updateBounds();
            }

            return () => {
                isMounted.current = false; // Mark component as unmounted
                listener.remove(); // Remove the event listener
            };
        }
    }, [map, updateBounds, visible]);

    const allSlotsMarkers = useMemo(() => {
        if (!bounds || frontStyleLvz < 4 || !lvzMarkerRealTime[marker]) {
          return [];
        }
        const {slots_data} = lvzMarkerRealTime[marker];
        if (!slots_data) {
            return null;
        }

        return Object.values(slots_data)
            .filter(slotData => bounds.contains(slotData.location))
            .map(slotData => {
                const { location, is_free, is_activated, slot_type, regulation_text } = slotData;
                const isFree = activated ? is_free : null; 
                
                return (
                    <LiveViewZoneSlotMarker
                        key={`${location.lat}-${location.lng}`}
                        position={location}
                        isFree={isFree}
                        isActivated={is_activated}
                        slotType={slot_type}
                        legend={regulation_text || slotTypeKey[slot_type]}
                        visible={visible}
                    />
                );
            });
       
    }, [lvzMarkerRealTime, bounds, visible]);

    return (
        <>
            {allSlotsMarkers}
            {lvzInfoWindow.visible && <LvzInfoWindow />}
        </>
    );
});

export default WrapperLiveViewZoneSlots