import React from 'react';
import { Marker } from '@react-google-maps/api';
import { extractGoogleDriveFileId, getGoogleDriveThumbnail } from '../global/GlobalUtils';

const CustomMarker = React.memo(({ position, iconUrl, description }) => {
    if (!position || !iconUrl) {        
        return null
    };

    // Extract and transform the icon URL
    const fileId = extractGoogleDriveFileId(iconUrl);
    const fixedIconUrl = getGoogleDriveThumbnail(fileId) || iconUrl;

    const customIcon = {
        url: fixedIconUrl,
        scaledSize: new window.google.maps.Size(50, 50),
    };

    return (
        <Marker
            position={position}
            icon={customIcon}
        />
    );
});

export default CustomMarker;
