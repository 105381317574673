import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//COMPONENTS
import PillBadge from "../global/PillBadge";
import ButtonGoPopup from '../popup/ButtonGoPopup';
import LvzCaptionPopup from '../popup/LvzCaptionPopup';
import PlaceholderParkingSpecificDetailsPanel from './PlaceholderParkingSpecificDetailsPanel';
import LiveIndic from "./LiveIndic";
import SpecificDetailsPanelCaptionPopup from '../popup/SpecificDetailsPanelCaptionPopup'


//REDUX SELECTORS
import { researchModeSelector } from '../global/state/researchModeSelector';
import { displayLvzSpecificDetailPanelSelector } from './state/displayLvzSpecificDetailPanelSelector';
import { lvzMarkerDataSelector } from '../markers/state/lvzMarkerDataSelector';
import { aroundMeModeSelector } from '../global/state/aroundMeModeSelector';
import { userLocationSelector } from '../markers/state/userLocationSelector';
import { lvzMarkerRealTimeSelector } from '../markers/state/lvzMarkerRealTimeSelector';
import { closestLvzPathsSelector } from './state/closestLvzPathsSelector';
import { closestLvzInfoSelector } from './state/closestLvzInfoSelector'

//REDUX ACTIONS
import { toggleLvzSpecificDetailPanel } from './state/displayLvzSpecificDetailPanelAction'
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction'
import { toggleExpandedDetailPanel } from './state/detailsPanelExpandedAction';
import { toggleSparkedMode } from '../global/state/sparkedModeAction';
import { showSparkedMarker } from '../markers/state/sparkedMarkerAction'
import { addSparkedParkingData } from '../sparkedMode/state/sparkedParkingDataAction'
import { addTtpInfo } from '../sparkedMode/state/ttpInfoAction'
import { updateSpecificLvzData, resetSpecificLvzData } from '../detailsPanel/state/specificLvzDataAction'

//UTILS
import * as global from '../global/globalVariable'
import { getDriveTimeAndPath, getWalkingTime } from '../global/MapUtils';

//STYLE
import * as IoIcon from 'react-icons/io5';
import * as MdIcon from 'react-icons/md';
import './ParkingSpecificDetailsPanel.css'


function LvzSpecificDetailsPanel(props){
   
    const dispatch = useDispatch()
    
    const researchMode = useSelector(researchModeSelector)
    const displayLvzSpecificDetailPanel = useSelector(displayLvzSpecificDetailPanelSelector)
    const lvzMarkerData = useSelector(lvzMarkerDataSelector)
    const aroundMeMode = useSelector(aroundMeModeSelector)
    const userLocation = useSelector(userLocationSelector)
    const lvzMarkerRealTime = useSelector(lvzMarkerRealTimeSelector)
    const closestLvzPaths = useSelector(closestLvzPathsSelector)
    const closestLvzInfo = useSelector(closestLvzInfoSelector)
    const config = useSelector((state) => state.config)

    const [lvzStaticData, setLvzStaticData] = useState()
    const [lvzDynamicData, setLvzDynamicData] = useState()
    const [drivingTimeToLvz, setDrivingTimeToLvz] = useState("-")
    const [walkingTimeToLvz, setWalkingTimeToLvz] = useState("-")
    //to hide/show popup to open itinerary with gmap or waze
    const [showPopupButtonGo, setShowPopupButtonGo] = useState(false)
    //to hide/show parking real time legend popup
    const [showPopupLvzCaption, setShowPopupLvzCaption] = useState(false)
    //to hide/show lvz caption popup
    const [showLvzPanelCaption, setShowLvzPanelCaption] = useState(false) 
    //to know which popup type legend to display (time or price)
    const [lvzCaptionType, setLvzCaptionType] = useState("")   
    
    //to display detail panel placeholder
    const [parkingDetailPanelPlaceholder, setParkingDetailPanelPlaceholder] = useState(true)

    function deactivateLvzDetailPanel(){
        dispatch(toggleLvzSpecificDetailPanel({value: false, name: ""}))
        dispatch(showDetailPanelContainer())

        if(researchMode.value){
            props.map.panTo(researchMode.location)
        }
    }

        //to switch the app to sparked mode (+ display sparker marker)
    //used when user click on "garé ici"
    function startSparkedMode(){
        dispatch(toggleSparkedMode(true, "offstreet"))
        dispatch(showSparkedMarker(lvzStaticData.location))
        let TTPinfo = {
            "position": lvzStaticData.location,
            "ttp": null,
            "start": Date.now(),
        };
        dispatch(addTtpInfo(TTPinfo))
        let sparkedLvzData = {
            name: lvzStaticData.name,
            price: {
                price1: "-",
                price3: "-",
                price24: "-",
            },
            location: lvzStaticData.location
        }
        dispatch(addSparkedParkingData(sparkedLvzData))
        dispatch(toggleExpandedDetailPanel(false))
        deactivateLvzDetailPanel()
    }

    //to display a specific legend popup based on which div has been clicked (time, price, features)
    function displayLvzDetailsPanelCaption(type){
        //to set the type of the legend to display
        setLvzCaptionType(type)
        //to display the popup
        setShowLvzPanelCaption(true)
    }

    useEffect(() => {

        if(displayLvzSpecificDetailPanel.value){
    
            //get and display static data from lvz (price)
            const lvzStaticData = lvzMarkerData[displayLvzSpecificDetailPanel.lvzId]
            setLvzStaticData(lvzStaticData)
            
            //get and display dynamic data from lvz (availability, free_slots)
            let lvzDynamic
            //get and display dynamic data from lvz (availability, free_slots)
            if(researchMode.value){
                lvzDynamic = closestLvzInfo.find(e=> e.lvz_id === displayLvzSpecificDetailPanel.lvzId)
                setLvzDynamicData(lvzDynamic)
            } else {
                lvzDynamic = lvzMarkerRealTime[displayLvzSpecificDetailPanel.lvzId]
                setLvzDynamicData(lvzDynamic)
            }
            
            let destination 
            
            //to get the direction either from userlocation or from cocoSearchMarker depending on researchMode being active or or
            if(researchMode.value && !aroundMeMode){
                destination = { lat: researchMode.location.lat, lng: researchMode.location.lng }
            } else {
                destination = { lat: userLocation.lat, lng: userLocation.lng }
            }
    
            //map fitbounds on lvz and userLocation or user destination 
            //if research mode is active when the lvz specific details panel opens
            // if(researchMode.value){
            //     const lvzItineraryPath = closestLvzPaths.find(e=> e.lvzId === displayLvzSpecificDetailPanel.lvzId)
            //     let bounds = new window.google.maps.LatLngBounds()
            //     bounds.extend(lvzStaticData.location)
            //     bounds.extend(destination)
            //     if(lvzItineraryPath){
            //         lvzItineraryPath.path.forEach(coord=>{
            //             bounds.extend(coord)
            //         })
            //     }
            //     props.map.fitBounds(bounds)
            //     const boundsZoom = props.map.getZoom()
            //     if(boundsZoom > 18){
            //         props.map.setZoom(18)
            //     }
            // }
    
            let lvzSelectorData = {}
            
            if(lvzStaticData){
                //used to display lvz price in lvz caption popup
                lvzSelectorData = {
                    price: {
                        price1: lvzStaticData.price_1_h ? lvzStaticData.price_1_h : "-",
                        price3: lvzStaticData.price_3_h ? lvzStaticData.price_3_h : "-",
                        price24: lvzStaticData.price_24_h ? lvzStaticData.price_24_h : "-"
                    }
                }
            }
            dispatch(updateSpecificLvzData({data : {...lvzSelectorData}}))
    
    
            const getWalkingAndDrivingTime = async () =>{
                //get and display driving and walking time to LVZ
                let drivingData = await getDriveTimeAndPath(destination, lvzStaticData.location)
                setDrivingTimeToLvz(drivingData.driveTime)
                dispatch(updateSpecificLvzData({drivingTime: drivingData.driveTime}))
                setTimeout(async ()=>{
                    let walkingData = await getWalkingTime(destination, lvzStaticData.location)
                    setWalkingTimeToLvz(walkingData)
                    dispatch(updateSpecificLvzData({walkingTime: walkingData}))
                }, 1000)
            }
    
            getWalkingAndDrivingTime()
    
            setParkingDetailPanelPlaceholder(false)
        }


        return () => {
            dispatch(resetSpecificLvzData())
        }

    }, [displayLvzSpecificDetailPanel])


    if (!config.parking_list && config?.customer !== 'amp') {
        setTimeout(() => {
            deactivateLvzDetailPanel()
        }, 30);
    }
    if(parkingDetailPanelPlaceholder){
        return (
            <PlaceholderParkingSpecificDetailsPanel/>
        )
    } else {
        return (
            <>
                <div style={{ visibility: config.parking_list || config?.customer === 'amp' ? 'visible' : 'hidden' }} className='specific-detail-panel-container flex-center-column'>
                    {researchMode.value ? 
                        <div className="close-parking-overview-div" onClick={()=>{deactivateLvzDetailPanel()}}>
                            <button className="city-map-button">
                                <div className="city-map-button-container">
                                    <span>Retourner aux résultats</span>
                                    <IoIcon.IoChevronForwardSharp style={{color: 'white',height: "24px", width: "24px"}}/>
                                </div>
                            </button>
                        </div>
                    : null }
                    {/* div croix verte */}
                    <div onClick={()=>{deactivateLvzDetailPanel()}}>
                        <IoIcon.IoClose 
                            style={{color: "var(--blue)", width: "20px", height: "20px"}}
                        />
                    </div>
                    {/* div parking title + real time */}
                    <div className="flex-space-between-inline" style={{width: "100%"}}>
                        {/* div parking title + adress */}
                        <div className="text-parking-div">
                            <p className="blue-title">{lvzStaticData && lvzStaticData.name ? lvzStaticData.name.toUpperCase() : null}</p>
                            <p className="grey-subtitle">{lvzStaticData && lvzStaticData.address ? lvzStaticData.address : "-"}</p>
                        </div>
                        {/* div real time + operator */}
                        <div className="flex-flex-end-column" style={{position: "relative"}}>
                            <div style={{width: "30px", height: "30px", position: "absolute", left: "-15px", top: '-15px'}}>
                                <LiveIndic/>
                            </div>
                            <PillBadge 
                                backgroundColor={ lvzDynamicData ? global.lvzColors[lvzDynamicData.availability].cssColorName : null}
                                text={lvzDynamicData ? lvzDynamicData.free_slots + " PLACES" : null}
                            />
                            {config?.customer !== 'amp' && <p className="grey-subtitle">DANS LA RUE</p>}
                        </div>
                    </div>
                    {/* div info parking (time, cost, option) */}
                    <div className="flex-space-between-inline pinfo_parkings" style={{width: "100%", height: "15vh"}}>
                        {/* div time */}
                        <div 
                            style={{
                                width: config?.customer === 'amp'  ? '50%' : undefined,
                                maxWidth: config?.customer === 'amp'  ? '50%' : undefined
                                }} 
                            className="flex-center-column pinfo_container"
                            onClick={()=> {displayLvzDetailsPanelCaption("time")}}
                        >
                            <div className="grid-container">
                                <div className="grid-item">
                                    <MdIcon.MdDriveEta 
                                    style={{color: "var(--grey)", width: "25px", height: "25px"}}
                                    />
                                </div>
                                <div className="grid-item">
                                    <span className="blue-text">{drivingTimeToLvz}min</span>
                                </div>
                                <div className="grid-item">
                                    <MdIcon.MdDirectionsWalk 
                                    style={{color: "var(--grey)", width: "25px", height: "25px"}}
                                    />    
                                </div>  
                                <div className="grid-item">
                                    <span className="blue-text">{walkingTimeToLvz}min</span>
                                </div>
                            </div>
                        </div>
                        {/* div cost */}
                        {config?.customer !== 'amp' &&
                        <div className="flex-center-column pinfo_container" onClick={()=> {displayLvzDetailsPanelCaption("price")}}>
                            <div className="flex-center-inline">
                                <p className="grey-text">1h:</p>
                                <p className="blue-text">{lvzStaticData &&  lvzStaticData.price_1_h ? lvzStaticData.price_1_h : "-"}€</p>
                            </div>
                            <div className="flex-center-inline">
                                <p className="grey-text">3h:</p>
                                <p className="blue-text">{lvzStaticData && lvzStaticData.price_3_h ? lvzStaticData.price_3_h : "-"}€</p>
                            </div>
                            <div className="flex-center-inline">
                                <p className="grey-text">24h:</p>
                                <p className="blue-text">{lvzStaticData && lvzStaticData.price_24_h ? lvzStaticData.price_24_h : "-"}€</p>
                            </div>
                        </div>
                        }
                        {/* div option */}
                        <div 
                            style={{
                                width:config?.customer === 'amp'  ? '50%' : undefined,
                                maxWidth:config?.customer === 'amp'  ? '50%' : undefined
                                }} 
                            className="pinfo_container featuresDiv"
                            onClick={()=>{setShowPopupLvzCaption(true)}}
                        >
                            <div>
                                <img src={"../images/legend/onstreet-parking-icon.png"} alt="onstreet icon"  style={{width: "50px"}}/>
                            </div>
                        </div>
    
                    </div>
                    {/* div button */}
                    <div className="flex-space-around-inline pactions-parkings">
                        <button className="pactions-button" onClick={()=> {setShowPopupButtonGo(true)}}>
                            <img style={{cursor: "pointer", width: "25px", verticalAlign: "middle"}} src="https://img.icons8.com/color/96/000000/google-maps.png" alt=""/>
                            <img style={{cursor: "pointer", width: "25px", verticalAlign: "middle"}} src="https://img.icons8.com/color/96/000000/waze.png" alt=""/>
                            Go !
                        </button>
                        <button className="pactions-button button-spark" onClick={()=> {startSparkedMode()}}>Garé ici</button>
                    </div>
                </div>     
                {showPopupButtonGo ? <ButtonGoPopup setTrigger={setShowPopupButtonGo} source={config?.customer} destination={lvzStaticData.location}/> : null}   
                {showPopupLvzCaption ? <LvzCaptionPopup setTrigger={setShowPopupLvzCaption}/> : null}
                {showLvzPanelCaption ? <SpecificDetailsPanelCaptionPopup setTrigger={setShowLvzPanelCaption} type={lvzCaptionType} entityType={"lvz"}/> : null}

            </>
        
        )
    }
}

export default LvzSpecificDetailsPanel
